// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-filling-js": () => import("./../../../src/pages/filling.js" /* webpackChunkName: "component---src-pages-filling-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-burger-template-js": () => import("./../../../src/templates/burgerTemplate.js" /* webpackChunkName: "component---src-templates-burger-template-js" */),
  "component---src-templates-filling-template-js": () => import("./../../../src/templates/fillingTemplate.js" /* webpackChunkName: "component---src-templates-filling-template-js" */)
}

